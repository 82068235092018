:root {
  color-scheme: dark;
  --bg-image: url("chest-bg-blur.d2492bc3.jpg");
  --button-color: #b38aef;
  --header-text-color: #fff;
  --accent-secondary: #451551;
  --accent-color: #d0b0ff;
  --bg-color: #2e0e36;
  --text-color: #fff;
  --card-main: #363b3d;
  --button-active: brightness(75%);
}

* {
  box-sizing: border-box;
}

body, html {
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100%;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

header {
  color: var(--header-text-color);
  background-image: var(--bg-image);
  text-shadow: 0 0 16px #0006;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(20vh + 20vw);
  padding: 10vw;
  display: flex;
}

h1 {
  margin: 0;
  font-size: max(20px, 5vw);
}

.tick {
  text-align: center;
  width: 80%;
  transition: opacity 1s;
  display: none;
}

.tick[data-state="initialised"] {
  opacity: 0;
  display: block;
}

.tick.loaded {
  opacity: 1;
}

.tick-group {
  margin: 2vw;
  position: relative;
}

.tick-label {
  text-align: center;
  width: 100%;
  margin-top: 4px;
}

.tick .tick-group:not(:last-child):after {
  content: ":";
  margin-left: 1.5vw;
  position: absolute;
  top: 0;
}

.announcements {
  color: var(--header-text-color);
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  width: 100%;
  overflow: hidden;
}

.latest-announcement {
  -webkit-backdrop-filter: brightness(150%);
  backdrop-filter: brightness(150%);
  padding-left: 60px;
  font-size: clamp(16px, 2vw, 22px);
  display: block;
  position: relative;
  box-shadow: 0 2px 10px #000;
}

.announcement-icon {
  background-color: var(--accent-color);
  align-items: center;
  width: 60px;
  height: 100%;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement-icon img {
  max-height: 100%;
}

.latest-announcement div.announcement {
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  margin: 0;
  padding: .75em 1.25em;
  display: flex;
  position: relative;
}

.latest-announcement div.announcement * {
  margin: 0 0 .75em;
}

.previous-announcements {
  justify-content: center;
  padding: 1em 0;
  display: flex;
}

.previous-announcements > * {
  position: relative;
  top: 2px;
}

.previous-announcements details {
  margin: 4px auto;
  padding-inline: 16px;
  display: inline-flex;
}

.previous-announcements details ul {
  text-align: left;
}

.previous-announcements summary {
  text-align: center;
  text-decoration: underline;
}

.previous-announcements summary::marker {
  content: "";
}

main {
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
}

.column {
  flex-direction: column;
  width: 50%;
  padding: 1vw;
  display: flex;
}

@media only screen and (width <= 640px) {
  main {
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin-block: 1vw;
    margin-inline: auto;
    padding-block: 0;
  }
}

@media only screen and (width <= 540px) {
  header {
    padding: 0;
  }
}

.card {
  -webkit-backdrop-filter: blur(4px);
  padding: 1.25vw calc(1.25vw + 12px);
  background-color: #0000;
  background-image: linear-gradient(#fff1 0%, #fff2 35% 46%, #8883 66%);
  border: 1px solid #fff3;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vw;
  padding: 5px;
  display: flex;
  box-shadow: 2px 3px 12px 4px #0006;
}

h2.card-title {
  text-align: left;
  color: #fff;
  text-shadow: 0 0 12px #000c;
  align-items: center;
  width: 100%;
  margin-block: 2px 8px;
  padding-inline: 6px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
}

h2.card-title .title-primary {
  margin-right: auto;
}

h2.card-title .subtitle {
  opacity: .8;
  font-size: 18px;
}

.card-content {
  background-color: var(--card-main);
  text-align: center;
  border: 1px solid #666;
  border-radius: 2px;
  width: 100%;
  padding: 6px 12px;
  font-size: 18px;
}

.card-content .content-paragraph {
  margin: 4px 2px 16px;
}

.card-button {
  background-color: var(--button-color);
  color: var(--accent-secondary);
  cursor: pointer;
  border: none;
  border-radius: 10px;
  min-width: 100px;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color .2s, filter .2s, -webkit-filter .2s;
  display: inline-block;
}

.card-button:hover {
  background-color: var(--accent-color);
  box-shadow: 0 8px 32px #1f268780;
}

.card-button:active {
  filter: var(--button-active);
}

a {
  color: var(--accent-color);
}

details {
  margin-block: 1vh;
  font-weight: bold;
}

details p {
  margin: 1vh;
  font-weight: normal;
}

summary {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

#map img {
  max-width: 100%;
}

.leaderboard-item {
  white-space: nowrap;
  text-align: left;
}

#leaderboard table {
  width: 100%;
}

#leaderboard table td:last-child {
  width: 100%;
  padding-left: 8px;
}

.leaderboard-bar {
  background-color: var(--accent-color);
  white-space: nowrap;
  text-align: left;
  color: var(--accent-secondary);
  border-radius: 5px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-block: 5px;
  padding: 4px;
}

.leaderboard-name {
  color: var(--accent-color);
}

#schedule table {
  width: 100%;
}

dialog {
  max-width: 90%;
}

dialog img {
  max-width: 100%;
}

/*# sourceMappingURL=style.19bd6821.css.map */
